import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import classes from "./App.module.css";
import Home from "./pages/home/Home";
import Section from "./pages/dashboard/Section";
import LoginSignUp from './pages/auth/LoginSignUp';
import ForgotPassword from "./pages/auth/ForgotPassword";
import Profile from "./pages/profile/Profile";

//useEffect
//useState
//hooks

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginSignUp />}></Route>
        <Route path="/cv_id/section_id" element={<Section />}></Route>
        <Route path='/login_sign_up' exact element={<LoginSignUp />} />
        <Route path='/forgot_password' exact element={<ForgotPassword />} />
        <Route path="/profile" exact element={<Profile />} />
      </Routes>
    </Router>
  );
};

export default App;
