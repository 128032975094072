import React, { useState } from 'react';
import classes from './Select.module.css'

const Select = ({onSelected=s=>s, defaultValue, selectedOption,options, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = selectedOption => () => {
        setIsOpen(false);
        onSelected(selectedOption)
    
    };
    
    return (
        <>
            <div className={classes.dropDownContainer} {...props}>
                <div className={classes.dropDownHeader} onClick={toggling}>
                    <div>{selectedOption || defaultValue}</div> 
                    <svg width="9" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L5 5L9 1" stroke="#793451" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                {isOpen && (
                    <div className={classes.dropDownListContainer}>
                        <ul className={classes.dropDownList}>
                        {options.map(option => (
                            <li className={classes.listItem} onClick={onOptionClicked(option)} key={Math.random()}>
                            {option}
                            </li>   
                        ))}
                        </ul>
                    </div>
                )}
            </div>
        </>
    )

}

export default Select