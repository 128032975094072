import React, { useState } from "react";
import Calendar from "./Calendar";


export default function Datepicker({ style = {}, title='Fecha de nacimiento', initialDate=(new Date()) }) {

  const [calendarOpen, setCalendarIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(initialDate)

  return (
    <div>
      <div style={{display: 'flex',justifyContent: 'space-around', alignItems: 'center',  width: "225px", border: '1.5px solid #D8CFC5',borderRadius: '5px', backgroundColor: 'white'}} onClick={() => setCalendarIsOpen(!calendarOpen)}>
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.34783 1V3.3913M8.65217 1V3.3913M1 5.30435C9.41739 5.30435 12 5.30435 12 5.30435M12 5.30435V2.43478H1V12H12V5.30435Z" stroke="#793451" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <div style={{color: '#A49D95'}}>
          <div>{ title }</div> 
          <div style={{fontSize: '.7em'}}>{ selectedDate.toLocaleString("es-MX", {year: 'numeric', month: 'numeric', day: 'numeric'}) }</div>
        </div>
        <svg width="9" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5 5L9 1" stroke="#793451" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
      </div>
        <Calendar
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
          shown={calendarOpen}
          close={() => {
            setCalendarIsOpen(false);
          }}
        />
    </div>
  );
}
