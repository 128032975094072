import { useLocation } from 'react-router-dom';
import styles from './ForgotPassword.module.css'
import Header from '../../components/layout/Header'
import Footer from "../../components/layout/Footer";
import Button from "../../components/UI/Button";

function ForgotPassword() {
    const location = useLocation();
    const data = location.state;
    if (data) {
        document.body.style.backgroundColor = "#F6A37B";
        console.log(data)
    }
   
    return <>
        <Header />
        <div className={styles.contentContainer}>
            <div className={styles.formContainer}>
                <h1>¿Olvidaste tu contraseña?</h1>
                <p>Coloca en el campo el correo con el <br/>  que creaste tu cuenta en BragIt</p>
                <label className={styles.inputLabel}>
                    Correo
                    <div>
                        <input 
                            className={styles.inputText}
                            placeholder="Correo"
                            name="email"
                            type={"text"}    
                        />
                    </div>
                </label>
                <Button type="highEmphasis">ENVIAR CORREO</Button>
            </div>    
        </div>
        <Footer />
    </>
}

export default ForgotPassword;