import classes from "./Profile.module.css";
import Header from '../../components/layout/Header'
import Footer from "../../components/layout/Footer";
// import { useLocation } from 'react-router-dom';
import Datepicker from "../../components/UI/datepicker/Datepicker";
import Checkbox from "../../components/UI/Checkbox";

const Profile = () => {
  // const location = useLocation();
  // const data = location.state;
  //   if (data) {
  //       document.body.style.backgroundColor = '#e5e5e5';
  //       console.log(data)
  //   }
  return (
    <>
    <Header />
      <div className={classes.contentContainer}>
        <div>
          <h1 style={{textAlign: 'center'}}>Perfil</h1>
        </div>
        <div className={classes.basicInfo}>
          <svg style={{margin: '30px'}} width="155" height="155" viewBox="0 0 155 155" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="77.5" cy="77.5" r="77.5" fill="#F6A37B"/>
            <path d="M4 56C4 56 0 56 0 52C0 48 4 36 24 36C44 36 48 48 48 52C48 56 44 56 44 56H4ZM24 32C27.1826 32 30.2348 30.7357 32.4853 28.4853C34.7357 26.2348 36 23.1826 36 20C36 16.8174 34.7357 13.7652 32.4853 11.5147C30.2348 9.26428 27.1826 8 24 8C20.8174 8 17.7652 9.26428 15.5147 11.5147C13.2643 13.7652 12 16.8174 12 20C12 23.1826 13.2643 26.2348 15.5147 28.4853C17.7652 30.7357 20.8174 32 24 32V32Z" transform="translate(55,45)" fill="#2C2C2C"/>
          </svg>
          
          <div>
            <h2>Nombre del usuario</h2>
            <Datepicker initialDate={new Date()}/>
            <div style={{display: 'flex'}}>
              <Checkbox text='Inglés' />
              <Checkbox text='Francés' />
              <Checkbox text='Alemán' />
              <Checkbox text='Chino' />
              <Checkbox text='Japonés' />
              <Checkbox text='Coreano' />
            </div>
          </div>

          
        </div>
       
      </div>
    <Footer />
    </>
  )
};

export default Profile;
