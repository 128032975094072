import React, { useState } from 'react';
import styles from './Checkbox.module.css';
// import {useFocusRing} from '@react-aria/focus'



const Checkbox = ({ text,children, onClick, disabled, style, ...props }) => {
    const [hover,setHover] = useState(false)

    const toggleHover = () => {
		setHover(!hover);
	};

  
    return <>

    <div className={styles.checkboxComponent}>
      <label style={{display: 'flex', alignItems: 'center', marginRight: '10px'}} htmlFor={text}>
        <input 
            id={text} type="checkbox" 
            {...props}
        />
          {text}
        </label> 
      </div>
    </>
  };

export default Checkbox;
