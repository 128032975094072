import React, { useState } from "react";
import { Link } from 'react-router-dom'
import styles from './LoginTab.module.css'
import Button from "../Button";
// import Input from "../Input";
import Checkbox from "../Checkbox";



const FirstTab = () => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const FBLogin = () => {
    console.log('FB')
  }

  


  return (
    <div className={styles.FirstTab}>
      <h1>Ingresa a tu cuenta</h1>
      <label className={styles.inputLabel}>
        <text>Usuario</text> 
        <div>
          <input 
            className={styles.inputText}
            placeholder="Usuario"
            name="username"
            type={"text"}    
          />
        </div>
      </label>
      <label className={styles.inputLabel}>
        <text>Contraseña</text> 
        <div>
          <input 
            className={styles.inputText}
            placeholder="Password"
            name="password"
            type={passwordShown ? "text" : "password"}    
          />
          
          <i onClick={togglePasswordVisiblity}>{EyeLogo()}</i>
        </div>
      </label>

      <Link state={'change'} className={styles.forgottenPasswordLink} to="/forgot_password">¿OLVIDASTE TU CONTRASEÑA? </Link>

      <Checkbox text='Recuérdame' />
      
      <Button type="highEmphasis">ENTRAR</Button>

      <div className={styles.alternativeLogin}>
        <div className={styles.alternativeLogin__title}>
          <div><hr/></div>
          <p>O ingresa con:</p>
          <div><hr/></div>
        </div>
        <div>
          {/* <Button style={{height:'40px', margin:'10px'}} fnClick={() => console.log('Facebook')} btnColor = '#3A589A' >
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 0.505913C11 0.373305 10.9473 0.246128 10.8536 0.15236C10.7598 0.0585913 10.6326 0.00591282 10.5 0.00591282H8C6.74112 -0.0567963 5.5086 0.381304 4.57172 1.22449C3.63485 2.06768 3.06978 3.24741 3 4.50591V7.20591H0.5C0.367392 7.20591 0.240215 7.25859 0.146447 7.35236C0.0526785 7.44613 0 7.5733 0 7.70591V10.3059C0 10.4385 0.0526785 10.5657 0.146447 10.6595C0.240215 10.7532 0.367392 10.8059 0.5 10.8059H3V17.5059C3 17.6385 3.05268 17.7657 3.14645 17.8595C3.24021 17.9532 3.36739 18.0059 3.5 18.0059H6.5C6.63261 18.0059 6.75979 17.9532 6.85355 17.8595C6.94732 17.7657 7 17.6385 7 17.5059V10.8059H9.62C9.73119 10.8075 9.83974 10.772 9.92848 10.705C10.0172 10.638 10.0811 10.5433 10.11 10.4359L10.83 7.83591C10.8499 7.76203 10.8526 7.68458 10.8378 7.6095C10.8231 7.53443 10.7913 7.46374 10.7449 7.40288C10.6985 7.34203 10.6388 7.29262 10.5704 7.25847C10.5019 7.22432 10.4265 7.20634 10.35 7.20591H7V4.50591C7.02487 4.25839 7.14111 4.02902 7.32602 3.86261C7.51093 3.69619 7.75123 3.60467 8 3.60591H10.5C10.6326 3.60591 10.7598 3.55323 10.8536 3.45947C10.9473 3.3657 11 3.23852 11 3.10591V0.505913Z" fill="white"/>
            </svg>
          </Button>
          <Button style={{height:'40px', margin:'10px'}} fnClick={() => console.log('Twitter')} btnColor = '#4099FF' >
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.3521 3.53039V3.98407C14.3596 5.30162 14.1238 6.60767 13.6581 7.8265C13.1924 9.04533 12.5063 10.1527 11.6394 11.0843C10.7725 12.016 9.74222 12.7535 8.60816 13.2539C7.4741 13.7544 6.25889 14.0079 5.03298 13.9998C3.24882 14.0054 1.50113 13.457 0 12.4207H0.811771C2.28388 12.4207 3.71331 11.8891 4.87062 10.9113C4.18463 10.9047 3.5182 10.6648 2.96694 10.2259C2.41568 9.78706 2.00791 9.17182 1.80213 8.46847C2.00884 8.5039 2.21787 8.5214 2.42719 8.52082C2.71712 8.51857 3.00584 8.48052 3.28767 8.4074C2.55424 8.2465 1.89506 7.81819 1.4217 7.19497C0.948332 6.57175 0.689852 5.79189 0.690005 4.98739C1.14386 5.25645 1.64957 5.40876 2.16743 5.43234C1.7105 5.10749 1.33612 4.66534 1.07816 4.14589C0.8202 3.62644 0.686793 3.04607 0.690005 2.45728C0.691017 1.84436 0.842206 1.24255 1.12836 0.712375C1.94965 1.80089 2.97452 2.69186 4.13658 3.3276C5.29865 3.96334 6.572 4.32966 7.87418 4.40284C7.82267 4.13881 7.79548 3.86996 7.793 3.60019C7.77185 2.88205 7.95746 2.17441 8.32449 1.57387C8.69151 0.973343 9.22206 0.509203 9.84368 0.24483C10.4653 -0.0195423 11.1477 -0.0712597 11.7977 0.0967401C12.4477 0.26474 13.0336 0.644267 13.4754 1.1835C14.2101 1.02489 14.9133 0.729644 15.5535 0.311047C15.309 1.12518 14.7948 1.81445 14.1086 2.24789C14.761 2.17212 15.3988 1.98971 16 1.70697C15.5548 2.40577 15.0064 3.02169 14.3765 3.53039H14.3521Z" fill="white"/>
            </svg>
          </Button> */}
          <Button style={{height:'40px', margin:'10px'}} fnClick={() => console.log('Google')} btnColor = '#E9544F' >
            <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.545 9.077V6.656H19.967V4.841H17.545V2.42H15.73V4.841H13.309V6.656H15.73V9.077H17.545ZM6.052 2.354C7.185 2.354 7.948 2.838 8.382 3.25L10.104 1.58C9.056 0.6 7.689 2.7646e-07 6.052 2.7646e-07C2.714 -0.000999724 0 2.711 0 6.052C0 9.393 2.714 12.105 6.052 12.105C9.545 12.105 11.864 9.648 11.864 6.192C11.864 5.689 11.8 5.332 11.711 4.952H6.049V7.208H9.381C9.223 8.159 8.37 9.764 6.052 9.764C4.049 9.764 2.415 8.099 2.415 6.06C2.415 4.018 4.049 2.354 6.052 2.354Z" fill="white"/>
            </svg>
          </Button>
        </div>
      </div>

      
    </div>
  );
};
export default FirstTab;

const EyeLogo = () => {
  return(
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25909 16C2.98636 16 0.15 10.4909 0.0409091 10.2182C0.0174514 10.1478 0.00408186 10.0774 0.000800495 10.0071C-0.00801549 9.81799 0.0809403 9.64502 0.175021 9.48078C0.810094 8.37213 3.67101 4 9.25909 4C15.5318 4 18.3682 9.50909 18.4773 9.78182C18.5007 9.85219 18.5141 9.92256 18.5174 9.99294C18.5262 10.182 18.4372 10.355 18.3432 10.5192C17.7081 11.6279 14.8472 16 9.25909 16ZM2.60519 8.08689C1.58335 9.16813 1.56644 10.8436 2.59033 11.9229C3.92821 13.3332 6.12753 14.9091 9.25909 14.9091C12.3979 14.9091 14.5778 13.3259 15.913 11.9131C16.9348 10.8319 16.9517 9.15641 15.9278 8.07711C14.59 6.66683 12.3906 5.09091 9.25909 5.09091C6.12029 5.09091 3.94034 6.67413 2.60519 8.08689Z" fill="#793451"/>
    <path d="M9.25919 14.909C6.53192 14.909 4.3501 12.7272 4.3501 9.99991C4.3501 7.27264 6.53192 5.09082 9.25919 5.09082C11.9865 5.09082 14.1683 7.27264 14.1683 9.99991C14.1683 12.7272 11.9865 14.909 9.25919 14.909ZM9.25919 6.18173C7.13192 6.18173 5.44101 7.87264 5.44101 9.99991C5.44101 12.1272 7.13192 13.8181 9.25919 13.8181C11.3865 13.8181 13.0774 12.1272 13.0774 9.99991C13.0774 7.87264 11.3865 6.18173 9.25919 6.18173Z" fill="#793451"/>
    <path d="M9.25901 12.7275C7.73174 12.7275 6.53174 11.5275 6.53174 10.0002C6.53174 9.67295 6.74992 9.45477 7.07719 9.45477C7.40447 9.45477 7.62265 9.67295 7.62265 10.0002C7.62265 10.9275 8.33174 11.6366 9.25901 11.6366C10.1863 11.6366 10.8954 10.9275 10.8954 10.0002C10.8954 9.07295 10.1863 8.36386 9.25901 8.36386C8.93174 8.36386 8.71356 8.14568 8.71356 7.8184C8.71356 7.49113 8.93174 7.27295 9.25901 7.27295C10.7863 7.27295 11.9863 8.47295 11.9863 10.0002C11.9863 11.5275 10.7863 12.7275 9.25901 12.7275Z" fill="#793451"/>
    <line className="visibleLine" x1="3.68448" y1="1.17746" x2="13.1775" y2="17.3155" stroke="#793451" stroke-linecap="round"/>
    </svg>
  )
}
