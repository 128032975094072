import styles from './Header.module.css'

function Header() {
    return <div className={styles.container}>
        <div className={styles.brandContainer}>
            <h1 className={styles.brand}>BragIt</h1>
        </div> 
    </div>
}

export default Header;