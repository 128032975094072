import React, { useState } from "react";

import calendar, {
  WEEK_DAYS,
  CALENDAR_MONTHS,
  getPlausibleYears
} from "../helpers/calendar";
import Select from '../Select'
import CalendarDate from "./CalendarDate"
import './datepicker.css'

let listOfPrevYears

export default function Calendar({setSelectedDate=f=>f, selectedDate=new Date(), shown, close}) {

  const [dateState, setDateState] = useState({
    current: new Date(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const getCalendarDates = () => {
    const { current, month, year } = dateState;
    const calendarMonth = month || +current?.getMonth() + 1;
    const calendarYear = year || current?.getFullYear();
    return calendar(calendarMonth, calendarYear);
  };

  const renderDayLabel = (day) => {
    const daylabel = WEEK_DAYS[day]
    return (
      <>
      <div style={{textAlign: 'center',fontFamily: 'Barlow',color: '#2C2C2C'}} key={Math.random()} >
        {daylabel}
      </div>
      </>
    );
  };


  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        close();
      }}
    >
    <div 
      style={{ width: "225px", overflow: 'hidden', border:'1px solid #D8CFC5', backgroundColor: 'white'}}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div>
        <div style={{display:'flex', justifyContent: 'space-evenly', margin: '20px'}}>
          <Select  
            onSelected={(value) => {
              setDateState({
                current: dateState.current,
                month: Object.values(CALENDAR_MONTHS).indexOf(value) +1,
                year: dateState.year,
              })  
            }}  
            defaultValue={Object.values(CALENDAR_MONTHS)[dateState.month - 1]} 
            options={Object.values(CALENDAR_MONTHS)} 
          />
          <Select 
            onSelected={(value) => {
              setDateState({
                current: dateState.current,
                month: dateState.month,
                year: value,
              })
            }} 
            defaultValue={dateState.year} 
            options={getPlausibleYears()} 
          />
        </div>
      </div>

      <div  style={{display: 'grid',gridTemplate: 'repeat(7, auto) / repeat(7, auto)', justifyContent: 'center'}}>
        <>{Object.keys(WEEK_DAYS).map(renderDayLabel)}</>
        <>{getCalendarDates().map((d, i) => 
          <CalendarDate 
            isToday={new Date(d.join("/")).toDateString()===new Date().toDateString()}  key={i} day={d[2]}  
            setDate={() => {setSelectedDate(new Date(d.join("/")))}}
            date={d}
            isSelected={selectedDate.toDateString() === new Date(d.join("/")).toDateString()}
            test={() => console.log(selectedDate.toDateString() == new Date(d.join("/")).toDateString() )}
            inCurrentMonth={new Date(d.join("/")).getMonth() + 1 === dateState.month}
          />)}
        </>
      </div>
     
    </div>
    </div>
  ) : null;
}

