import React, { useState } from "react";

import styles from './SignUpTab.module.css'
import Button from "../Button";

const SecondTab = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
    <div className={styles.secondTab}>
      <h1>Regístrate</h1>
      <p>es gratuito</p>
      <label className={styles.inputLabel}>
          <text>Usuario</text> 
          <div>
            <input 
              className={styles.inputText}
              placeholder="Usuario"
              name="username"
              type={"text"}    
            />
          </div>
        </label>
        <label className={styles.inputLabel}>
          <text>Correo</text> 
          <div>
            <input 
              className={styles.inputText}
              placeholder="Correo"
              name="email"
              type={"text"}    
            />
          </div>
        </label>
        <label className={styles.inputLabel}>
          <text>Contraseña</text> 
          <div>
            <input 
              className={styles.inputText}
              placeholder="Password"
              name="password"
              type={passwordShown ? "text" : "password"}    
            />
            <i onClick={togglePasswordVisiblity}>{EyeLogo()}</i>
          </div>
        </label>
        <label className={styles.inputLabel}>
          <text>Confirmar contraseña</text> 
          <div>
            <input 
              className={styles.inputText}
              placeholder="Password"
              name="password"
              type={passwordShown ? "text" : "password"}    
            />
            <i onClick={togglePasswordVisiblity}>{EyeLogo()}</i>
          </div>
        </label>
        <Button type="highEmphasis">REGISTRARME</Button>
      </div>
    </>
  );
};
export default SecondTab;

const EyeLogo = () => {
  return(
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25909 16C2.98636 16 0.15 10.4909 0.0409091 10.2182C0.0174514 10.1478 0.00408186 10.0774 0.000800495 10.0071C-0.00801549 9.81799 0.0809403 9.64502 0.175021 9.48078C0.810094 8.37213 3.67101 4 9.25909 4C15.5318 4 18.3682 9.50909 18.4773 9.78182C18.5007 9.85219 18.5141 9.92256 18.5174 9.99294C18.5262 10.182 18.4372 10.355 18.3432 10.5192C17.7081 11.6279 14.8472 16 9.25909 16ZM2.60519 8.08689C1.58335 9.16813 1.56644 10.8436 2.59033 11.9229C3.92821 13.3332 6.12753 14.9091 9.25909 14.9091C12.3979 14.9091 14.5778 13.3259 15.913 11.9131C16.9348 10.8319 16.9517 9.15641 15.9278 8.07711C14.59 6.66683 12.3906 5.09091 9.25909 5.09091C6.12029 5.09091 3.94034 6.67413 2.60519 8.08689Z" fill="#793451"/>
    <path d="M9.25919 14.909C6.53192 14.909 4.3501 12.7272 4.3501 9.99991C4.3501 7.27264 6.53192 5.09082 9.25919 5.09082C11.9865 5.09082 14.1683 7.27264 14.1683 9.99991C14.1683 12.7272 11.9865 14.909 9.25919 14.909ZM9.25919 6.18173C7.13192 6.18173 5.44101 7.87264 5.44101 9.99991C5.44101 12.1272 7.13192 13.8181 9.25919 13.8181C11.3865 13.8181 13.0774 12.1272 13.0774 9.99991C13.0774 7.87264 11.3865 6.18173 9.25919 6.18173Z" fill="#793451"/>
    <path d="M9.25901 12.7275C7.73174 12.7275 6.53174 11.5275 6.53174 10.0002C6.53174 9.67295 6.74992 9.45477 7.07719 9.45477C7.40447 9.45477 7.62265 9.67295 7.62265 10.0002C7.62265 10.9275 8.33174 11.6366 9.25901 11.6366C10.1863 11.6366 10.8954 10.9275 10.8954 10.0002C10.8954 9.07295 10.1863 8.36386 9.25901 8.36386C8.93174 8.36386 8.71356 8.14568 8.71356 7.8184C8.71356 7.49113 8.93174 7.27295 9.25901 7.27295C10.7863 7.27295 11.9863 8.47295 11.9863 10.0002C11.9863 11.5275 10.7863 12.7275 9.25901 12.7275Z" fill="#793451"/>
    <line className="visibleLine" x1="3.68448" y1="1.17746" x2="13.1775" y2="17.3155" stroke="#793451" stroke-linecap="round"/>
    </svg>
  )
}