import React, { useState } from 'react';
import styles from './Button.module.css';
// import {useFocusRing} from '@react-aria/focus'



const Button = ({ children,fnClick,  btnColor = '#793451', labelColor, disabled, type, style, ...props }) => {
    const [hover,setHover] = useState(false)

    const toggleHover = () => {
		setHover(!hover);
	};

  // let {isFocusVisible, focusProps} = useFocusRing();

  
  const commonStyles = {
      backgroundColor: btnColor,
      color: labelColor || 'white'
  };

  const disabledStyle = {
		cursor: 'default',
		backgroundColor: btnColor,
		color: labelColor || 'white',
		opacity: 0.4
	};

  const outlineHoverStyle = {
		color: labelColor || 'white',
    boxShadow: `3px 6px 2px 0px rgba(121,52,81,0.3)`
	};

  

    let btnStyle;
	  switch (type) {
		case 'rounded':
			btnStyle = commonStyles
			break;
		case 'block':
			btnStyle = commonStyles
			break;
		case 'highEmphasis':
			if (hover) {
				btnStyle = outlineHoverStyle
			}
			 else {
        btnStyle = commonStyles
      }     
    break;
    default:
      btnStyle = {
        backgroundColor: btnColor,
        color: labelColor || 'white'
      };
    break;
	}

 
    function createRipple(event) {
      const button = event.currentTarget;
  
      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
  
      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
      circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
      circle.classList.add("ripple");
  
      const ripple = button.getElementsByClassName("ripple")[0];
  
      if (ripple) {
        ripple.remove();
      }
  
      button.appendChild(circle);
    }

  
    return <>

    <button
        style={
            disabled ? { ...commonStyles, ...btnStyle, ...disabledStyle, ...style } :
            { ...commonStyles, ...btnStyle, ...style } 
        }
        {...props}
		    type="button"
        onMouseEnter={toggleHover}
		    onMouseLeave={toggleHover}       
        onClick={(event) => {
          createRipple(event)
          if (fnClick) {
            fnClick();
          }
        }}
       
        
        >{children}
    </button>
    </>
    
  };

export default Button;
