import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

function Footer() {
    return <div className={styles.container}>
        <div id='footerContainer' className={styles.footerContainer}>
            <div className={styles.footerLinks}>
                <Link id='footerLink1' className={styles.footerLink} to="/aviso-de-privacidad">aviso de privacidad</Link> 
                <Link id='footerLink2' className={styles.footerLink} to="/terminos-y-condiciones">términos y condiciones</Link>
            </div>
            <p id='copyright' className={styles.copyright}>© BragIt 2022</p>
        </div> 
    </div>
}

export default Footer;