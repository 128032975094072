import React, { useState } from "react";
import Header from '../layout/Header'
import Footer from "../layout/Footer";
import FirstTab from "./AllTabs/LoginTab";
import SecondTab from "./AllTabs/SignUpTab";
import './Tabs.css';

const Tabs = () => {
    const [activeTab, setActiveTab] = useState("tab1");

    //  Functions to handle Tab Switching
    const handleTab1 = () => {
        // update the state to tab1
        setActiveTab("tab1");
    };
    const handleTab2 = () => {
        // update the state to tab2
        setActiveTab("tab2");
    };
    
    if (activeTab === 'tab1') {
        document.body.style.backgroundColor = "#ebe1d8";
        // document.getElementById('copyright').style.color ='#2C2C2C'
        // document.getElementById('footerContainer').style.borderTop ='2px solid #A49F97'
        // document.getElementById('footerLink1').style.color ='#793451'
        // document.getElementById('footerLink1').style.borderRight ='1px solid #A49F97'
        // document.getElementById('footerLink2').style.color ='#793451'
    } else if (activeTab === 'tab2') {
        document.body.style.backgroundColor = "#C2859F";
        document.getElementById('copyright').style.color ='#2C2C2C'
        document.getElementById('footerContainer').style.borderTop ='2px solid #2C2C2C'
        document.getElementById('footerLink1').style.color ='#2C2C2C'
        document.getElementById('footerLink1').style.borderRight ='1px solid #2C2C2C'
        document.getElementById('footerLink2').style.color ='#2C2C2C'
    } 

    return (
        <>
        <Header />
        <div className="Tabs">
            {/* Tab nav */}
            <ul className="nav">
                <li
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={handleTab1}
                >
                    Inicia sesión
                </li>
                <li
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={handleTab2}
                >
                    Resgístrate
                </li>
            </ul>
            <div className="outlet">
                {activeTab === "tab1" ? <FirstTab /> : <SecondTab />}
            </div>
        </div>
        <Footer />
        </>
    );
};
export default Tabs;